import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Layout from '../components/Layout';

import resourcesTabs from '../constants/resources-tabs';

import '../assets/styles/pages/resources-page.scss';

const Resources = () => {
  const [courseType, setCourseType] = useState(resourcesTabs.ENGLISH_TEACHING_JOBS);

  return (
    <Layout title={{ id: 'resources.seo.title' }} description={{ id: 'resources.seo.description' }}>
      <div className="resources-page">
        <section className="c-first-section c-first-section--md">
          <div className="container courses-section">
            <h1 className="title">
              <FormattedMessage id="resources.sec1.title" />
            </h1>
            <p className="description">
              <FormattedMessage id="resources.sec1.description" />
            </p>
            <div className="courses-section__btns">
              <button
                onClick={() => setCourseType(resourcesTabs.ENGLISH_TEACHING_JOBS)}
                className={`btn ${
                  courseType === resourcesTabs.ENGLISH_TEACHING_JOBS ? 'btn--active' : ''
                }`}
              >
                <FormattedMessage id="resources.tab1.text" />
              </button>
              <button
                onClick={() => setCourseType(resourcesTabs.ENGLISH_TEACHING_RESOURCES)}
                className={`btn ${
                  courseType === resourcesTabs.ENGLISH_TEACHING_RESOURCES ? 'btn--active' : ''
                }`}
              >
                <FormattedMessage id="resources.tab2.text" />
              </button>
              <button
                onClick={() => setCourseType(resourcesTabs.BARCELONA)}
                className={`btn ${courseType === resourcesTabs.BARCELONA ? 'btn--active' : ''}`}
              >
                <FormattedMessage id="resources.tab3.text" />
              </button>
              {/* <button
                onClick={() => setCourseType(resourcesTabs.SEVILLIE)}
                className={`btn ${courseType === resourcesTabs.SEVILLIE ? 'btn--active' : ''}`}
              >
                <FormattedMessage id="resources.tab4.text" />
              </button> */}
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="container">
            <div
              className={`inner-wrapper ${
                courseType === resourcesTabs.ENGLISH_TEACHING_JOBS ? 'active' : ''
              }`}
            >
              <div className="externals">
                <a target="_blank" href="https://es.jooble.org/" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab1.r1.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab1.r1.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.lingobongo.com/barcelona/index.php?lang_from_url=en"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab1.r2.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab1.r2.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.tefl.com/job-seeker/search.html"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab1.r3.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab1.r3.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.infojobs.net/ofertas-trabajo/barcelona/english-teacher"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab1.r4.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab1.r4.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.tefljobsworld.com/#sthash.u27kl3J3.dpbs"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab1.r5.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab1.r5.title" />
                  </span>
                </a>
              </div>
            </div>
            <div
              className={`inner-wrapper ${
                courseType === resourcesTabs.ENGLISH_TEACHING_RESOURCES ? 'active' : ''
              }`}
            >
              <div className="externals">
                <a
                  target="_blank"
                  href="https://learnenglish.britishcouncil.org/skills/listening"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r1.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r1.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://breakingnewsenglish.com/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r2.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r2.text" />
                  </span>
                </a>
                <a target="_blank" href="http://iteslj.org/questions/" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r3.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r3.text" />
                  </span>
                </a>
                <a target="_blank" href="http://www.grammar.net/" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r4.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r4.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.theguardian.com/education/tefl"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r5.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r5.text" />
                  </span>
                </a>
                <a target="_blank" href="https://www.englishpage.com/" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r6.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r6.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://freeenglishlessonplans.com/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab2.r7.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab2.r7.text" />
                  </span>
                </a>
              </div>
            </div>
            <div
              className={`inner-wrapper ${courseType === resourcesTabs.BARCELONA ? 'active' : ''}`}
            >
              <div className="externals">
                <a
                  target="_blank"
                  href="https://www.barcelona-metropolitan.com/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab3.r1.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab3.r1.text" />
                  </span>
                </a>
                <a target="_blank" href="http://barcelona.lecool.com/" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab3.r2.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab3.r2.text" />
                  </span>
                </a>
                <a target="_blank" href="https://www.tmb.cat/en/home" className="external-link">
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab3.r3.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab3.r3.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.renfe.com/es/en/cercanias/cercanias-barcelona"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab3.r4.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab3.r4.text" />
                  </span>
                </a>
              </div>
            </div>
            {/* <div
              className={`inner-wrapper ${courseType === resourcesTabs.SEVILLIE ? 'active' : ''}`}
            >
              <div className="externals">
                <a
                  target="_blank"
                  href="https://notjustatourist.com/the-12-best-places-to-watch-flamenco-seville/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab4.r1.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r1.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://notjustatourist.com/the-8-best-museums-in-seville/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    {' '}
                    <FormattedMessage id="resources.tab4.r2.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r2.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.ibericoclub.com/the-10-best-tapas-bars-in-seville/#gref"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab4.r3.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r3.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.visitasevilla.es/en/history/sevilla-through-its-parks"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab4.r4.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r4.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://sevillecityguide.com/events-seville.html"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab4.r5.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r5.text" />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://welcometoseville.com/flea-markets-in-seville/"
                  className="external-link"
                >
                  <span className="external-link__title">
                    <FormattedMessage id="resources.tab4.r6.title" />
                  </span>
                  <span className="external-link__description">
                    <FormattedMessage id="resources.tab4.r6.text" />
                  </span>
                </a>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Resources;
